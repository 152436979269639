<template>
    <el-dialog :close-on-click-modal="false" :close-on-press-escape="false" :title="imgType == 1 ? markTitle : '图标选择'"
        :append-to-body="true" :visible.sync="dialogModifyImg" :width="width" :before-close="handleClose" :center="true">
        <div class="dialog_box">
            <div class="dialog_box_content dialog_box_color" v-if="imgType == 1"
                style="padding:30px 0px;text-align: center;">
                <div class="dialog_box_content_box">
                    <div class="flex" style="margin-bottom:15px;">
                        <span>名称：</span>
                        <el-input style="width:180px;height:25px;" :disabled="editBool" maxlength="10" v-model="markerName"
                            placeholder="请输入名称"></el-input>
                    </div>
                    <div class="flex">
                        <span>图标：</span>
                        <div class="flex">
                            <img style="margin-right: 10px;"
                                :src="imgUrl ? xinextendUrlapi + imgUrl : require('../../assets/map/mark.png')" alt="">
                            <span class="dialog_box_content_btntextcl mouse" v-if="!editBool" @click="upImg">更换图标</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="dialog_box_content" v-else>
                <div class="dialog_box_content_tab flex">
                    <div v-for="item in imgTab" :key="item.id"
                        :class="['mouse', imgTabId == item.id ? 'dialog_box_color' : '']" @click="switchTab(item.id)">
                        {{ item.lable }}
                    </div>
                </div>
                <div class="dialog_box_content_bottom dialog_box_color flex" style="flex-wrap: wrap;">
                    <div :class="['dialog_box_content_bottom_img mouse', imgType != 3 && item.img == imgListUrl || imgType == 3 && item.isSelect ? 'dialog_box_content_bottom_border' : '']"
                        v-for="(item, index) in imgList" :key="item.id" @click="selectedImg(item, index)">
                        <img :src="item.id == 0 ? require('../../assets/map/mark.png') : xinextendUrlapi + item.img" alt="">
                    </div>
                    <div class="dialog_box_content_bottom_img" v-if="imgType != 3">
                        <i v-if="imgList.length == 10" @click="uploadImg" class="el-icon-plus mouse"></i>
                        <el-upload v-else class="upload-demo" ref="upload" action="apiurl" :on-preview="handlePreview"
                            :on-remove="handleRemove" :on-change="headchange" :show-file-list="false" :auto-upload="false">
                            <span slot="trigger"><i class="el-icon-plus mouse"></i></span>
                        </el-upload>
                    </div>
                    <div @click="removeImg" v-if="imgType != 3" class="dialog_box_content_bottom_img mouse"><i
                            class="el-icon-minus"></i></div>
                </div>
            </div>
            <div slot="footer" class="bottom">
                <el-button class="bc" @click="submitForm()">{{ bottonText }}{{ imgType == 3 ? '删除' : '' }}</el-button>
                <el-button class="gb" v-if="imgType != 2" @click="resetForm()">{{ deleteBut }}</el-button>
            </div>
        </div>
    </el-dialog>
    <!-- </div> -->
</template>

<script>
import beasconfig from "@/api/beasconfig.js"
import { addMark, updateMark, deleteMark, getQueryIcon, getUploadIcon, deleteIcon } from "@/administration/upmarker.js";
export default {
    props: {
        mark: {
            type: Object,
            default: () => ({})
        },
        edittype: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            imgTab: [
                {
                    id: 1,
                    lable: '通用'
                },
                {
                    id: 2,
                    lable: '警保'
                },
                {
                    id: 3,
                    lable: '消防'
                },
                {
                    id: 4,
                    lable: '化工'
                },
                {
                    id: 5,
                    lable: '其他'
                }
            ],
            imgTabId: 1,
            imgList: [
                {
                    img: require('../../assets/map/mark.png'), //完整路径：项目url+icon+img
                    creator: 12,
                    id: 0
                }
            ],
            imgListId: 0,
            markerName: '', //标记点名称
            imgListUrl: null,
            imgUrl: null,
            dialogModifyImg: true,
            bottonText: '保存',
            deleteBut: '删除',
            markTitle: '查看标记',
            fileData: {},
            imgType: 1,
            editBool: false,  //编辑
            xinextendUrlapi: beasconfig.xinextendUrlapi + '/icon',
            width: "440px"
        };
    },
    created() {
        this.imgTabId = this.mark.type ? this.mark.type : 1;
        this.imgListId = this.mark.iId ? this.mark.iId : 0;
        this.markerName = this.mark.name ? this.mark.name : '';
        this.imgUrl = this.mark.img ? this.mark.img : null;
        this.imgListUrl = this.imgUrl;
        let bool = this.mark.id;
        this.bottonText = bool ? '编辑' : '保存';
        this.deleteBut = bool ? '关闭' : '删除';
        this.editBool = bool ? true : false;
        this.markTitle = bool ? '查看标记' : '添加标记';
        this.getList();
    },
    beforeCreate: function () { },
    methods: {
        uploadImg() {     //上传图片
            if (this.imgList.length >= 10) {
                this.$MessageWarning('最大支持添加10个图标');
                return
            }
        },
        selectedImg(item, index) {  //选中图片
            if (this.imgType == 3) {
                if (this.imgTabId == 1 && index == 0) {
                    this.imgList[index].isSelect = false;
                } else {
                    this.imgList[index].isSelect = !this.imgList[index].isSelect;
                }
            } else {
                this.imgListUrl = item.img;
                this.imgListId = item.id;
            }
        },
        upImg() {  //更换图标
            this.imgType = 2;
            this.width = "760px";
            this.bottonText = "确定";
            this.deleteBut = "删除";
        },
        removeImg() {  //删除图标
            this.imgType = 3;
        },
        switchTab(id) {
            if (this.imgTabId == id) {

                return
            }
            this.imgList = [];
            this.imgTabId = id;
            this.getList();
        },
        handleRemove(file) {
            console.log(file);
        },
        handlePreview(file) {
            console.log(file);
        },
        headchange(file) {
            let types = ["image/svg", "image/svg+xml", "image/gif", "image/bmp", "image/jpeg", "image/jpg", "image/png"];
            const isImage = types.includes(file.raw.type);
            const isLt2M = file.size < 1024 * 5;
            const _URL = window.URL || window.webkitURL;
            // let bool = false;
            if (!isImage) {
                this.$MessageWarning('图片格式错误');
                return
            }

            if (!isLt2M) {
                this.$MessageWarning('上传图片大小不能超过5kb!');
                return
            }

            // if (bool) {
            //     this.$MessageError('上传失败：图片最大支持像素25×25！')
            //     return
            // }

            this.fileData.file = file.raw;
            var formdata = new FormData();
            let params = {
                file: this.fileData.file,
                orgId: this.$store.state.userInfo.org_id,
                creator: this.$store.state.userInfo.User_ID,
                type: this.imgTabId
            }
            Object.keys(params).map((item) => {
                if (params[item] != "") {
                    formdata.append(item, params[item]);
                }
            });
            new Promise((resolve, reject) => {
                const img = new Image()
                let width = 25;
                let height = 25;
                img.onload = function () {
                    img.width > width || img.height > height ? reject() : resolve()
                }
                img.src = _URL.createObjectURL(file.raw)
            }).then(
                () => {
                    // bool = false;
                    getUploadIcon(formdata).then(res => {
                        if (res.data.code == 200) {
                            this.$MessageSuccess('上传成功')
                            this.getList();
                        } else {
                            this.$MessageError('上传失败')
                        }
                    })
                    return Promise.resolve(true);  //file
                },
                () => {
                    this.$MessageError('上传失败：图片最大支持像素25×25！')
                    // bool = true;
                    return Promise.reject(false)
                }
            )
        },
        handleClose() {     //关闭
            if (this.imgType == 1) {
                if (!this.editBool && this.mark.id) {
                    this.editBool = true;
                    this.bottonText = "编辑";
                    this.markTitle = "查看标记";
                    this.deleteBut = "关闭";
                } else {
                    this.$emit('cloneMark');
                }
            } else {
                this.width = "440px";
                this.imgType = 1;
                this.imgListUrl = this.imgUrl;
                this.imgListId = this.mark.iId ? this.mark.iId : 0;
                this.bottonText = "编辑";
                this.deleteBut = "删除";
            }
        },
        submitForm() {      //保存
            let find = this.imgList.find(item => this.imgListUrl == item.img);
            let isBool = find ? true : false;  //判断切换的时候是否选择
            let isDy = this.imgListId <= 0;
            console.log(isDy,22222222222,this.imgListId)
            if (this.imgType == 1) {
                if (this.mark.id) {
                    if (!this.editBool) {  //点击了编辑才能操作
                        if (isBool) {
                            let params = {
                                orgId: this.$store.state.userInfo.org_id,
                                creator: this.$store.state.userInfo.User_ID,
                                iconId: this.imgListId,
                                markId: this.mark.id,
                                name: this.markerName,
                            }
                            updateMark(params).then(res => {
                                if (res.data.code == 200) {
                                    this.$MessageSuccess('操作成功');
                                    let url = isBool && !isDy? this.imgListUrl : null;
                                    this.imgUrl = url;
                                    this.mark.img = url;
                                    this.$emit('successMark', this.mark);
                                } else {
                                    this.$MessageError('操作失败');
                                }
                            })
                        } else {
                            this.$emit('cloneMark');
                        }
                    } else {
                        this.editBool = false;
                        this.bottonText = "保存";
                        this.markTitle = "编辑标记";
                        this.deleteBut = "删除";
                    }
                } else {
                    let params = {
                        orgId: this.$store.state.userInfo.org_id,
                        creator: this.$store.state.userInfo.User_ID,
                        iconId: this.imgListId,
                        name: this.markerName,
                        lat: `${this.mark.lat}`,
                        lng: `${this.mark.lng}`
                    }
                    addMark(params).then(res => {
                        if (res.data.code == 200) {
                            this.$MessageSuccess('操作成功');
                            let url = isBool && !isDy ? this.imgListUrl : null;
                            this.imgUrl = url;
                            this.mark.img = url;
                            this.$emit('successMark', this.mark);
                        } else {
                            this.$MessageError('操作失败');
                        }
                    })
                }
                // this.$emit('successMark', this.mark);
            } else if (this.imgType == 2) {
                this.width = "440px";
                this.imgType = 1;
                this.imgUrl = isBool && !isDy || !isBool && !isDy? this.imgListUrl : null;
                
            } else if (this.imgType == 3) {
                let imgList = this.imgList.filter(item => item.isSelect);
                if (imgList.length <= 0) {
                    this.$MessageWarning('请选择需要删除的图标');
                    return
                }
                let uids = imgList.map(item => item.id).join();
                let bool = imgList.some(item => item.img == this.imgUrl);
                deleteIcon({
                    orgId: this.$store.state.userInfo.org_id,
                    creator: this.$store.state.userInfo.User_ID,
                    iconIds: uids
                }).then(res => {
                    if (res.data.code == 200) {
                        if (bool) {
                            this.mark.img = null;
                            this.$emit('successMark', this.mark);
                        }
                        this.$MessageSuccess('删除成功');
                        this.imgType = 2;
                        this.getList();
                    } else {
                        this.$MessageError('删除失败');
                    }
                })
            }
        },
        resetForm() {
            if (this.imgType == 1) { //如果类型为1的时候直接关闭弹出框 不是则是返回更换图标弹出框
                if (!this.editBool) {
                    // this.editBool = true;
                    // this.bottonText = "编辑";
                    // this.markTitle = "查看标记";
                    // this.deleteBut = "关闭";
                    if (this.mark.id) {
                        deleteMark({
                            orgId: this.$store.state.userInfo.org_id,
                            creator: this.$store.state.userInfo.User_ID,
                            markId: this.mark.id
                        }).then(res => {
                            if (res.data.code == 200) {
                                this.$MessageSuccess('删除成功');
                                this.$emit('successMark', this.mark);
                            } else {
                                this.$MessageError('删除失败');
                            }
                        })
                    } else {
                        this.$emit('cloneMark');
                    }
                } else {
                    this.$emit('cloneMark');
                }
            } else {
                this.imgList.forEach(item => item.isSelect = false);
                this.imgListUrl = this.imgUrl;
                this.imgType = this.imgType == 3 ? 2 : 1;
            }
        },
        focus() {    //获取焦点
            this.$store.state.isFocusing = true;
        },
        blur() {     //失去焦点
            this.$store.state.isFocusing = false;
        },
        getList() {  //获取图片列表
            let params = {
                orgId: this.$store.state.userInfo.org_id,
                category: 2,
                type: this.imgTabId
            }
            getQueryIcon(params).then(res => {
                let imgList = res.data.data
                if (imgList) {
                    imgList.forEach((item) => {
                        item.isSelect = false;
                        if (item.img == this.mark.img) this.imgListUrl = item.img;
                        if (item.id == 0 && !this.imgListUrl) this.imgListUrl = item.img;
                    })
                    this.imgList = imgList;
                }
            })
        }
    },
    mounted() {

    },
    watch: {

    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.el-dialog__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

/deep/.el-dialog {
    background: #0F3343;
    margin-top: 0px !important;

    &__title {
        color: #fff;
        font-size: 16px;
    }

    .el-dialog__headerbtn .el-dialog__close {
        color: #4AFFFF !important;
    }



    .el-input__inner {
        width: 195px;
        height: 35px;
        background-color: #08222E;
        border: 1px solid #07415A;
        color: #fff;
    }

}

.dialog_box {
    width: 100%;

    &_color {
        background-color: #08222E;
    }

    &_content {
        width: 80%;
        margin: auto;
        color: #fff;

        &_box {
            // width: 200px;
            display: inline-block;
            margin: auto;
        }

        &_btntextcl {
            color: var(--main-color);
        }

        &_tab {
            width: 100%;

            &>div {
                width: 80px;
                height: 80px;
                line-height: 80px;
                text-align: center;
            }
        }

        &_bottom {
            min-height: 80px;
            padding: 20px;

            &_img {
                width: 38px;
                height: 38px;
                border: 1px solid #565656;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 5px;
                margin-right: 15px;
                font-size: 25px;
                margin-bottom: 10px;

                &>img {
                    width: 38px;
                    height: 38px;
                }
            }

            &_border {
                border: 1px solid #ffffff;
            }
        }
    }
}

/deep/.el-upload--picture-card:hover,
.el-upload:focus {
    border-color: var(--main-color);
    color: var(--main-color);
}

/deep/.gb.el-button {
    width: 89px;
    height: 38px;
    border: 1px solid #fff !important;
    background-color: transparent !important;
    color: #fff !important;
}

/deep/.bc.el-button {
    width: 89px;
    height: 38px;
    border: 1px solid #4AFFFF !important;
    background-color: transparent !important;
    color: #4AFFFF !important;
    margin-right: 30px;
}

/deep/.el-form-item__error {
    padding-top: 0px !important;
}

.bottom {
    width: 100%;
    text-align: center;
    padding: 20px 0px;
}

/deep/.el-dialog--center .el-dialog__body {
    padding: 0px !important;
}

/deep/.el-dialog .el-input__inner {
    width: 100%;
    height: 100%;
    border-radius: 0px;
}

.flex {
    display: flex;
    align-items: center;
}
</style>